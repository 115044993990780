<template>
  <div class="projectData">
    <!-- <el-form :model="queryInfo" class="ly-header__bg" label-width="100px">
      <el-form-item label="场景属性：">
        <dic-radio-button
          :code.sync="queryInfo.sceneProperties"
          type-code="038"
          query-name="administrativeLevel"
          name="场景属性"
          @onChange="onChange"
        />
      </el-form-item>
      <el-form-item label="筛选条件：" class="filterInfo">
        <el-tag
          closable
          v-for="(item, index) in tags"
          :key="index + item.name"
          @close="closeTag(index, item.tagName)"
        >
          {{ item.name }}：{{
            queryInfo[item.tagName] ? queryInfo[item.tagName] : "不限"
          }}
          <span
            class="circle"
            v-if="item.hasOwnProperty('valLength') && item.valLength > 0"
            >+{{ item.valLength }}</span
          >
        </el-tag>
      </el-form-item>
    </el-form> -->

    <div class="ly-container__bg">
      <div class="ly_operation">
        <div class="left">
          <el-button type="primary" icon="jr-iconjr-icon-add" @click="addItem"
            >添加场景</el-button
          >
          <!-- <el-button
            type="primary"
            icon="jr-iconjr-icon-add"
            @click="addItemSpecial"
            >添加特殊场景</el-button
          > -->
          <!-- <import-data moduleName="政府数据" @fileClick="fileClick" /> -->
          <!-- <el-button @click="multiDelete">
            批量删除
            <i class="jr-iconjr-icon-delect" />
          </el-button>
          <popover @popoverClick="popoverClick" /> -->
          <div class="upload_time">
            <div class="caretTime" @click="uploadTime" style="color: #606266">
              按上传时间
            </div>
            <i
              class="el-icon-caret-top"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 0
                  ? 'isActiveTime1'
                  : 'iconColor',
              ]"
              @click="caretTop"
            />
            <i
              class="el-icon-caret-bottom"
              :class="[
                queryInfo.columnName == 'create_time' && queryInfo.order == 1
                  ? 'isActiveTime1'
                  : 'iconColor',
              ]"
              @click="caretBottom"
            />
          </div>
        </div>
        <el-input
          placeholder="根据场景名称、场景描述进行全文搜索"
          v-model="queryInfo.queryConditions"
          class="lager-input"
          @change="search"
        >
          <template slot="append">
            <div @click="search">搜索</div>
          </template>
        </el-input>
      </div>
      <el-table
        class="ly_table"
        :data="projectTable"
        :header-cell-style="{ background: '#F5F6F7', color: '#363E4D' }"
        @selection-change="handleSelectionChange"
        :default-sort="{ prop: 'date', order: 'descending' }"
        @sort-change="sortChange"
        :cell-style="set_cell_style"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55" align="center" />
        <template slot="empty">
          <img src="@/assets/img/table.png" alt="没有数据" srcset="" />
        </template>
        <el-table-column label="序号" align="center" width="80">
          <template v-slot="scope">
            <span
              >{{
                (queryInfo.pageNum - 1) * queryInfo.pageSize +
                (scope.$index + 1)
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column  label="场景图片" align="center">
          <template slot-scope="scope">
            <el-popover
              placement="right"
              trigger="hover"
              v-if="scope.row.sceneImg"
            >
              <img
                :src="scope.row.sceneImg"
                style="max-height: 300px; max-width: 300px"
              />
              <img
                slot="reference"
                :src="scope.row.sceneImg"
                style="max-height: 100px; max-width: 100px; cursor: pointer"
                @click="editItem(scope.row)"
              />
            </el-popover>
            <div @click="editItem(scope.row)" style="cursor: pointer" v-else>
              <el-image
                :src="require('../../assets/img/pro.png')"
                class="headerImg"
                style="height: 100px; width: 100px; cursor: pointer"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="sceneName"
          label="场景名称"
          align="center"
          width="230"
        >
          <template v-slot="{ row }">
            <div @click="editItem(row)" style="cursor: pointer">
              <span>{{ row.sceneName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="sceneType"
          label="场景类型"
          align="center"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="sceneDescribe"
          label="场景描述"
          align="center"
          width="350"
        >
          <template slot-scope="scope">
            <div>
              <el-popover
                placement="right"
                width="300"
                trigger="hover"
                :content="scope.row.sceneDescribe"
              >
                <div slot="reference">
                  <div
                    v-if="
                      scope.row.sceneDescribe !== null &&
                      scope.row.sceneDescribe !== ''
                    "
                  >
                    {{ scope.row.sceneDescribe.substring(0, 20) }}
                    <span v-if="scope.row.sceneDescribe.length > 20">...</span>
                  </div>
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="sceneSituation"
          label="场景现状"
          align="center"
          width="350"
        >
          <template slot-scope="scope">
            <div>
              <el-popover
                placement="right"
                width="300"
                trigger="hover"
                :content="scope.row.sceneSituation"
              >
                <div slot="reference">
                  <div
                    v-if="
                      scope.row.sceneSituation !== null &&
                      scope.row.sceneSituation !== ''
                    "
                  >
                    {{ scope.row.sceneSituation.substring(0, 20) }}
                    <span v-if="scope.row.sceneSituation.length > 20">...</span>
                  </div>
                </div>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createName"
          label="创建人"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          width="120"
          :formatter="formatTime"
        ></el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template v-slot="{ row }">
            <button-table
              @buttonClick="editItem(row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteItem(row)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50, 100, 200]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import Popover from "@/components/Popover.vue";
// import DataTime from '@/components/DataTime.vue'
import ImportData from "@/components/ImportData.vue";
import DicTagButton from "@/components/DicTagButton.vue";
import DicRadioButton from "@/components/DicRadioButton.vue";
import ButtonTable from "@/components/ButtonTable.vue";
import DataTime from "../Product/com/dataTime.vue";
import { pagination } from "@/mixins/pagination";
import { listScenarioBasicPage, deleteById } from "@/api/Sceneknowledge";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import { updateProjectData } from "@/api/numberProject";
import { exportData } from "@/utils/tool";
export default {
  name: "ProjectData",

  mixins: [pagination],

  components: {
    ImportData,
    DicTagButton,
    DicRadioButton,
    ButtonTable,
    DataTime,
    Popover,
    DicCheckboxGroup,
  },

  data() {
    return {
      // 查询
      queryInfo: {
        columnNames: ["update_time"],
        // columnNames:["update_time"],
        pageSize: 10,
        pageNum: 1,
        queryConditions: "", // 搜索查询条件
        // id: [],
        // projectCountry: '', // 国家
        // projectProvince: '', // 省份
        // projectCity: '', // 城市
        // projectStage: '', // 项目阶段
        // reportTags: [], // 行业类别
        // reportTagsName: '', // 行业辅助标签
        // fieldId: [], // 领域类别
        // fieldIdName: '', // 领域辅助标签
        order: 1, // 0正序 1倒叙
        // size: '',
      },
      projectTable: [], // 表格数据
      tags: [
        {
          name: "场景属性",
          tagName: "administrativeLevel",
        },
      ],
      delete: {
        id: [],
        multiId: [],
      },
      dialogType: "行业类别",
      dialogVisible: false,
      editTableObj: {
        projectIndustryType: [], // 行业
        industryChildrenId: [], // 领域类别二级标签
        projectFieldType: [], // 领域
        fieldChildrenId: [], // 领域类别二级标签
      },
      valueI: 0,
    };
  },
  created() {
    this.search();
  },

  methods: {
    fileClick() {
      this.search();
    },
    async search() {
      const res = await listScenarioBasicPage(this.queryInfo);

      if (res.code === 200) {
        this.projectTable = res.data.list;
        this.projectTable.forEach((el,index)=>{
          el.sceneSituation=el.sceneSituation.replace(/<[^>]+>/g,"")
          el.sceneDescribe=el.sceneDescribe.replace(/<[^>]+>/g,"")
          
        })
        this.total = res.data.total;
        this.projectTable.forEach((el) => {
          if (el.dataIntegerity) {
            el.dataIntegerity = Number(el.dataIntegerity);
          } else {
            el.dataIntegerity = 0;
          }
        });
      }

      this.total = res.data.total;
    },
    searchCountry(val) {
      this.queryInfo.country = val;
      this.search();
    },
    searchProvince(val) {
      this.queryInfo.province = val;
      this.search();
    },
    searchCity(val) {
      if (val != "北京" && val != "天津" && val != "上海" && val != "重庆") {
        this.queryInfo.city = val;
        this.search();
      }
    },
    // 弹窗修改
    async saveItem() {
      if (this.editTableObj.fieldChildrenId) {
        this.editTableObj.projectFieldType =
          this.editTableObj.projectFieldType.concat(
            this.editTableObj.fieldChildrenId
          );
      }

      if (this.editTableObj.industryChildrenId) {
        this.editTableObj.projectIndustryType =
          this.editTableObj.projectIndustryType.concat(
            this.editTableObj.industryChildrenId
          );
      }

      this.editTableObj.projectIndustryType = [
        ...new Set(this.editTableObj.projectIndustryType),
      ];
      this.editTableObj.projectFieldType = [
        ...new Set(this.editTableObj.projectFieldType),
      ];

      const res = await updateProjectData(this.editTableObj);

      if (res.code === 200) {
        this.$message.success("编辑成功");
      }

      this.dialogVisible = false;

      this.search();
    },
    // 弹窗关闭
    handleClose() {
      this.dialogVisible = false;

      this.$refs.checkboxProject.clear();
    },
    updateCode(val, tag) {
      // this.nextTick(() => {
      switch (tag) {
        case "领域":
          this.editTableObj.fieldChildrenId = val;
          break;

        case "行业":
          this.editTableObj.industryChildrenId = val;
          break;

        default:
          break;
      }
      // })
    },
    async uploadTime() {
      switch (this.valueI) {
        case 0:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 1;
          this.valueI++;
          break;
        case 1:
          this.queryInfo.columnName = "create_time";
          this.queryInfo.order = 0;
          this.valueI++;
          break;
        case 2:
          this.queryInfo.columnName = "update_time";
          this.queryInfo.order = 1;
          this.valueI = 0;
          break;
      }
      this.search();
    },
    dateClick(obj) {
      if (obj.year == "0") {
        this.queryInfo.years = "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.search();
      } else if (obj.month == "0") {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = "";
        this.queryInfo.day = "";
        this.search();
      } else if (obj.day == "0") {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = "";
        this.search();
      } else {
        this.queryInfo.years = obj.year + "";
        this.queryInfo.month = obj.month + "";
        this.queryInfo.day = obj.day + "";
        this.search();
      }
    },

    // 字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },

    // 查询条件更变时el-tag跟着改变
    onChange(val, queryName, name, checkboxVal) {
      let childrenVal = [];

      if (!this.tags.some((item) => item.tagName === queryName)) {
        if (name === "行业类别" || name === "领域类别" || name === "技术类别") {
          this.tags.push({
            name,
            tagName: queryName,
            valLength: 0,
          });
        } else {
          this.tags.push({
            name,
            tagName: queryName,
          });
        }
      }

      // 拿到name在tags的下标
      let result;
      result = this.tags.findIndex((el) => {
        return el.name === name;
      });

      switch (name) {
        case "行业类别":
          this.queryInfo.activityIndustry = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.activityIndustry =
              this.queryInfo.activityIndustry.concat(childrenVal);
          } else {
            this.tags[result].valLength = 0;
          }

          break;

        case "领域类别":
          this.queryInfo.activityField = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.activityField =
              this.queryInfo.activityField.concat(childrenVal);
          } else {
            this.tags[result].valLength = 0;
          }
          break;

        case "技术类别":
          this.queryInfo.activityTech = [val];

          if (checkboxVal && checkboxVal.length && val) {
            this.tags[result].valLength = checkboxVal.length;

            childrenVal = checkboxVal;

            this.queryInfo.activityTech =
              this.queryInfo.activityTech.concat(childrenVal);
          } else {
            this.tags[result].valLength = 0;
          }

          break;

        default:
          break;
      }
      this.queryInfo.pageNum = 1;
      this.search();
    },
    formatTime(row, column) {
      const date = new Date(row[column.property]);
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    },
    // tag标签关闭
    closeTag(index, name) {
      this.tags.splice(index, 1);

      switch (name) {
        case "activityIndustryName":
          this.queryInfo.activityIndustryName = "";
          this.queryInfo.activityIndustry = [];
          break;
        case "activityTechName":
          this.queryInfo.activityTechName = "";
          this.queryInfo.activityTech = [];
          break;
        case "activityFieldName":
          this.queryInfo.activityFieldName = "";
          this.queryInfo.activityField = [];
          break;
        default:
          this.queryInfo[name] = "";
          break;
      }
      this.search();
    },
    editItem(row) {
      // const data = row.id.toString();
      // this.$router.push({
      //   name: 'commercialInfo',
      //   query: {
      //     personType: "1",
      //     id: data,
      //     name:row.governmentName
      //   },
      // })
      if (row.sceneProperties == "特殊场景") {
        let text = this.$router.resolve({
          path: "/knowledgeData/sceneknowledge/specialInfo",
          query: {
            personType: 1,
            id: row.id,
          },
        });
        window.open(text.href, "_blank");
      } else {
        let text = this.$router.resolve({
          path: "/knowledgeData/sceneknowledge/tabIndex",
          query: {
            personType: 1,
            id: row.id,
          },
        });
        window.open(text.href, "_blank");
      }
    },

    addItem() {
      this.$router.push({
        // name: "SceneknowledgeNormal",
        path: '/knowledgeData/sceneknowledge/tabIndex'
      });
    },
    addItemSpecial() {
      this.$router.push({
        name: "SceneknowledgeSpecial",
      });
    },

    // 点击文字切换正倒序
    caretText() {
      this.queryInfo.order = !this.queryInfo.order;

      if (this.queryInfo.order) {
        this.queryInfo.order = 1;
      } else {
        this.queryInfo.order = 0;
      }

      this.search();
    },
    // 正序
    caretTop() {
      this.queryInfo.order = 0;

      this.search();
    },
    // 倒序
    caretBottom() {
      this.queryInfo.order = 1;

      this.search();
    },
    handleSelectionChange(val) {
      this.delete.multiId = val.map((item) => {
        return item.id;
      });
      this.queryInfo.id = val.map((item) => {
        return item.id;
      });
    },
    // _deleteProject(params) {
    //   this.$confirm("确认删除吗 ?", "提示")
    //     .then(async () => {
    //       const res = await deleteById(params);

    //       if (res.code === 200) {
    //         this.$message.success("删除成功");
    //         this.search();
    //       }
    //     })
    //     .catch(() => {});
    // },
    async deleteItem(row) {
      // this.delete.id.push(row.id);
      // this._deleteProject(this.delete.id);
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示").then(
        async () => {
          const res = await deleteById({ id: row.id });
          if (res.code == 200) {
            this.$message.success("删除成功");
            this.search();
          } else {
            this.$$message.error("删除失败");
          }
        }
      );
    },
    // multiDelete() {
    //   if (this.delete.multiId.length > 0) {
    //     this._deleteProject(this.delete.multiId);
    //   } else {
    //     this.$message.warning("请选择至少一条数据");
    //   }
    // },
    sortChange(column) {
      this.pageNum = 1;
      if (column.prop === "dataIntegerity") {
        this.queryInfo.columnName = "data_integerity";
        if (column.order === "ascending") {
          this.queryInfo.order = 0;
        } else if (column.order === "descending") {
          this.queryInfo.order = 1;
        } else {
          this.queryInfo.order = 1;
          this.queryInfo.columnName = "update_time";
        }
      }
      this.search();
    },
    set_cell_style({ row, column, rowIndex, columnIndex }) {
      if (column.label === "领域类别" || column.label === "行业类别") {
        return "cursor:pointer";
      }
    },
    // 批量导出
    popoverClick(val) {
      if (val === "all") {
        Object.assign(this.queryInfo, { id: [], size: "" });
      } else if (val == 0 && this.queryInfo.id.length) {
        // Object.assign(this.queryInfo, {
        //   id: this.queryInfo.id,
        // });
      } else {
        Object.assign(this.queryInfo, { size: val });
      }

      exportData(
        this.queryInfo, // 后台需要的参数
        "/government/exportExcel", // 路径
        "application/msexcel", // 下载的文件类型
        this.search, // 下载完之后调查询方法
        "政府数据.xls"
      );
    },
  },
};
</script>
<style lang="less" scoped>
.projectData {
  /deep/ .el-input-group__append {
    background: #1e9fff;
    color: #fff;
    border: 1px solid #1e9fff;
  }
  .caretTime:hover {
    cursor: pointer;
  }
}
.isActiveTime1 {
  color: #1e9fff !important;
}
.iconColor {
  color: #c0c4cc !important;
}
/deep/ .filterInfo .el-form-item__content {
  padding: 0;
}
</style>
